var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"uk_prd_20241214.2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/assets/uk_prd_Canonical_tag_issue_fix_for_PLP_pages-20241212.26";

import * as Sentry from '@sentry/nextjs'
import { rewriteFramesIntegration } from '@sentry/integrations'

const sentryCommonInit = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
}

const sentryInitProd = {
  ...sentryCommonInit,
  // Adjust this value in production, or use tracesSampler for greater control
  // Setting to 1% initially
  tracesSampleRate: 0.01,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  // Setting to 10% initially
  sampleRate: 0.1,
  maxBreadcrumbs: 30,
  sendClientReports: false,
  integrations: [
    rewriteFramesIntegration(),
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
  ],
}

const sentryInitLowerEnvs = {
  ...sentryCommonInit,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  sampleRate: 1,
  maxBreadcrumbs: 50,
  sendClientReports: false,
  integrations: [
    rewriteFramesIntegration(),
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
  ],
}

// This condition is to Check if the current site is UK as we are enabling sentry only for UK environments
const isSiteUK = process.env.NEXT_PUBLIC_SITE_KEY === 'interflora-co-uk'
// we are using NEXT_PUBLIC_ALGOLIA_ENV_PREFIX instead of NODE_ENV because NEXT_PUBLIC_ALGOLIA_ENV_PREFIX takes diff values for uat and prod
const sentryInit = process.env.NEXT_PUBLIC_ALGOLIA_ENV_PREFIX === 'prd' ? sentryInitProd : sentryInitLowerEnvs
//we can remove this "if" check once we are ready to integrate sentry to UK prod and for all interflora-ie and flying-flowers environments
if (isSiteUK) {
  Sentry.init(sentryInit)
}
